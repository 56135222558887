<template>
	<section class="contactSeeAlso" :class="theme">
		<div class="container1">
			<div class="right" :style="colStyle">
				<router-link
					v-for="(item, index) in d.list"
					:key="index"
					:to="item.url"
					class="slimLnk"
				>
					{{ item.title }}
					<span class="ic-icarrow-right"></span>
				</router-link>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "contactSeeAlso",
	props: {
		theme: {
			type: String,
		},
	},
	computed: {
		colStyle() {
			return {
				gridTemplate: `auto / repeat(${
					this.d.list.length > 3 ? 3 : this.d.list.length
				},1fr)`,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.contactSeeAlso {
	padding: 30px 0;
	.container1 {
		// @include container;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.right {
			display: grid;
			grid-template: auto / 1fr 1fr 1fr;
			grid-gap: 10px;
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			@media (max-width: $ts) {
				display: block;
			}
			.slimLnk {
				position: relative;
				padding: 15px 15px 50px;
				@include t3;
				text-decoration: none;
				background-color: $cgray7;
				@media (max-width: $ts) {
					display: block;
					& + .slimLnk {
						margin-top: 16px;
					}
				}
				&:hover {
					background-color: $cgray6;
				}
				.ic-icarrow-right {
					position: absolute;
					bottom: 15px;
					left: 15px;
					font-size: 24px;
					color: $cred1;
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>