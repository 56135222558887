<template>
	<div>
		<img :src="path(d.image)" :alt="path(d.image)" />
	</div>
</template>

<script>
export default {
	name: "ContactImageElement",
};
</script>

<style lang="scss" scoped>
div {
	img {
		width: 100%;
	}
}
</style>