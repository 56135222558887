<template>
	<section class="AddresBlock">
		<div>
			<h2>{{ d.title }}</h2>
		</div>
		<div>
			<div class="itemsWrp">
				<div class="item" v-for="(item, index) in d.list" :key="index">
					<template v-if="item.link"
						><a :href="item.link" class="title">{{ item.title }}</a></template
					>
					<template v-else>
						<span class="title">{{ item.title }}</span>
					</template>
					<div class="sub" v-html="item.text"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "AddresBlock",
};
</script>

<style lang="scss" scoped>
.AddresBlock {
	display: grid;
	grid-template-columns: 1fr 2fr;
	h2 {
		@include h5;
	}
	.itemsWrp {
		margin-top: 10px;
		.item {
			.title {
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 160%;
				letter-spacing: -0.025em;
				color: #e1251b;
			}
			.sub {
				@include t3l;
			}
		}
	}
}
</style>