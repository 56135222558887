<template>
	<section class="donorCenters">
		<div class="inputWrp">
			<span class="ic-icsearch"></span>
			<input type="text" v-model.trim="search" :placeholder="d.placeholder" />
		</div>
		<template v-if="centersToView.length > 0">
			<div
				class="CenterItem"
				v-for="(item, index) in centersToView"
				:key="index"
			>
				<div>
					<div class="name">{{ item.city }}</div>
				</div>
				<div class="desc">
					<div>
						<div v-html="item.text"></div>
						<div class="links">
							<template v-for="(soc, index) in item.socials">
								<a
									v-if="soc.link"
									:key="index"
									:href="soc.link"
									target="_blank"
									>{{ soc.name }}</a
								>
							</template>
						</div>
					</div>
					<div>
						<a
							class="prone"
							v-for="(tel, index) in item.phones"
							:key="index"
							:href="tel.link"
							>{{ tel.name }}</a
						>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div v-if="search != ''">{{ $t("plasmacenterNotfound") }}</div>
			<div v-else>plasmacenters empty</div>
		</template>
	</section>
</template>

<script>
export default {
	name: "ContactsDonorCenters",
	data() {
		return {
			centers: [],
			search: "",
			selectedUId: null,
		};
	},
	methods: {
		// onItemSelect(id) {
		// 	this.selectedUId = id;
		// },
		// onSubmit() {
		// 	if (this.selectedUId != null) {
		// 		this.$emit("submitslide", this.centers[this.selectedUId]);
		// 	}
		// },
	},
	computed: {
		centersToView() {
			if (this.search != "") {
				let a = [];
				this.centers.forEach((item) => {
					if (item.city.toLowerCase().match(this.search.toLowerCase()))
						a.push(item);
				});
				return a;
			} else return this.centers;
		},
	},
	created() {
		this.centers = this.d.list;
		// this.axios
		// 	.post("/api/easyweek/get-plasmacenters", { lang: this.$i18n.locale })
		// 	.then((response) => {
		// 		this.centers = response.data.data.models;
		// 		this.centers.forEach((item, idx) => {
		// 			item.UId = idx;
		// 		});
		// 		if (this.initialData) {
		// 			this.centers.forEach((item, idx) => {
		// 				if (this.initialData.model.id == item.model.id)
		// 					this.selectedUId = idx;
		// 			});
		// 		}
		// 	});
	},
};
</script>

<style lang="scss" scoped>
.donorCenters {
	padding-top: 40px;
	.inputWrp {
		position: relative;
		margin-bottom: 10px;
		.ic-icsearch {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
		input {
			border: none;
			@include t3l;
			outline: none;
			width: 100%;
			padding-left: 30px;
			border-bottom: 1px solid #000;
			&:placeholder-shown {
				color: #7f8192;
			}
		}
	}
	.CenterItem {
		padding: 10px 0 20px;
		display: grid;
		grid-template-columns: 1fr 2fr;
		& + .CenterItem {
			border-top: 1px solid $cgray6;
		}
		@media (max-width: $tm) {
			grid-template-columns: 1fr;
		}
		.name {
			@include h4;
		}
		.desc {
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-gap: 30px;
			@media (max-width: $ts) {
				grid-template-columns: 1fr;
				grid-gap: 0;
			}
			.links {
				a {
					color: $cred1;
					margin-right: 10px;
				}
			}
			.prone {
				display: block;
				@include t2l;
				color: $cred1;
				margin-bottom: 20px;
				margin: 16px 0;
			}
		}
	}
}
</style>