<template>
    <main>
        <transition name="fade">
            <div v-if="isLoaded">
                <section class="contactsPageSec">
                    <div class="container">
                        <div class="breadWrp">
                            <bread-crumbs
                                :items="bc"
                                :last="true"
                            ></bread-crumbs>
                            <a href="#" class="back"
                                >Назад <span class="decoLine1"></span
                            ></a>
                        </div>
                        <div class="tileWrp">
                            <h1>{{ translate.title }}</h1>
                        </div>
                        <div class="sideSectionWrp"></div>
                        <div>
                            <template v-for="(item, index) in constructor">
                                <component
                                    v-if="item.visibility == '1'"
                                    :key="item.component + index"
                                    :is="item.component"
                                    :d="item.content"
                                    :id="item.widget_id"
                                    :textprop="item.component"
                                    :theme="item.content.theme || null"
                                ></component>
                            </template>
                        </div>
                    </div>
                </section>
            </div>
        </transition>
    </main>
</template>

<script>
import BreadCrumbs from "../components/Parts/BreadCrumbs.vue";
import ContactImageElement from "../components/Parts/FormSteps/ContactImageElement.vue";
import AddresBlock from "../components/Sections/contact/AddresBlock.vue";
import ContactsDonorCenters from "../components/Sections/contact/ContactsDonorCenters.vue";
import ContactSeeAlso from "../components/Sections/contact/ContactSeeAlso.vue";
import ContactForm from "../components/Sections/contact/FeedBackForm.vue";
import TextTabs from "../components/Sections/TextTabs.vue";
export default {
    components: {
        BreadCrumbs,
        "contacts-list": AddresBlock,
        ContactForm,
        "see-also": ContactSeeAlso,
        "image-element": ContactImageElement,
        "donor-centers": ContactsDonorCenters,
        "slider-tabs": TextTabs
    },
    name: "ContactsPage",
    data() {
        return {
            isLoaded: false,
            constructor: null,
            model: null,
            translate: null
        };
    },
    methods: {},
    computed: {
        bc() {
            if (this.locale == "uk")
                return [
                    { text: "Головна", url: "/" },
                    { text: "Контакти", url: "/kontakty" }
                ];
            else if ( this.locale == "ru" ) {
                return [
                    { text: "Головна", url: "/ru" },
                    { text: "Контакти", url: "/ru/kontakty" }
                ];
            } else {
                return [
                    { text: "Home", url: "/en" },
                    { text: "Contacts", url: "/en/kontakty" }
                ];
            }

        }
    },
    created() {
        this.axios
            .post("/api/page/get-by-slug", {
                lang: this.locale,
                slug: this.$route.params.slug
            })
            .then(response => {
                this.constructor = response.data.data.constructor;
                this.model = response.data.data.model;
                this.translate = response.data.data.translate;
                document.title =
                    response.data.data.translate.meta_title ||
                    response.data.data.translate.title;
                this.isLoaded = true;
            })
            .catch(({ response }) => {
                this.solveStatus(response.status);
            });
    }
};
</script>

<style lang="scss">
.contactsPageSec {
    padding: 30px 0;
    @media (max-width: $ts) {
        padding: 10px 0 30px;
    }
    .container {
        @include container;
        display: grid;
        grid-template-columns: 25% calc(75% - 1px);
        @media (max-width: $tl) {
            grid-template-columns: 1fr;
        }
        .breadWrp {
            border-right: 1px solid $cgray6;
            @media (max-width: $tl) {
                order: -2;
                border-bottom: unset !important;
                border-right: none !important;
            }
            @media (max-width: $ts) {
                padding: 10px 0;
            }
            .breadCrumbs {
                @media (max-width: $tl) {
                    display: none;
                }
            }
            .back {
                display: none;
                color: $cred1;
                position: relative;
                text-decoration: none;
                padding-bottom: 3px;
                .decoLine1 {
                    background-color: $cred1;
                }
                @media (max-width: $ts) {
                    display: inline-block;
                }
            }
        }
        .breadWrp,
        .sideSectionWrp {
            margin-right: 50px;
            border-right: 1px solid $cgray6;
        }
        .sideSectionWrp {
            @media (max-width: $tl) {
                order: 1;
                margin: 12px 0 22px;
            }
        }
        .breadWrp,
        .tileWrp {
            border-bottom: 1px solid $cgray6;
        }
        .tileWrp {
            @media (max-width: $tl) {
                order: -1;
            }
            h1 {
                @include h2mob;
                margin: 0 0 30px;
            }
        }
        .prodGridWrp {
            @media (max-width: $tl) {
                order: 3;
            }
        }

        .lnkWrp {
            padding: 15px 0;
            .button2 {
                background-color: #fff;
                border: 1px solid $cgray6;
                &:hover {
                    background-color: $cgraybg1;
                }
            }
        }
        .pagiWrp {
            display: flex;
            justify-content: flex-end;
            padding-top: 30px;
        }
    }
}
</style>
