<template>
    <section class="textTabsSection" :class="theme">
        <div v-if="$route.name == 'kontakti'" class="containerContact" :style="style">
            <div class="title">
                <h2>
                    {{ d.title }}
                </h2>
            </div>
            <Flickity
                class="tabsCaptions"
                ref="tabsSlider"
                :options="tabsSliderOptions"
            >
                <div
                    href="#"
                    v-for="(item, index) in d.list"
                    :key="index"
                    class="item"
                    @click="currentTab = index"
                    :class="{ active: currentTab == index }"
                >
                    {{ item.tab }}
                    <span class="decoLine1"></span>
                </div>
            </Flickity>
            <div class="slides">
                <div class="bigLeftImg articleWrp">
                    <img
                        v-if="path(currentTabItem.image)"
                        :src="path(currentTabItem.image)"
                        alt="123"
                    />
                    <div>
                        <h2>{{ currentTabItem.title }}</h2>
                        <div v-html="currentTabItem.text"></div>
                    </div>
                    <p
                        v-if="currentTabItem.btn_name && currentTabItem.link"
                        class="moreBlk"
                    >
                        <router-link :to="currentTabItem.link" class="moreLnk"
                            >{{ currentTabItem.btn_name }}
                            <span class="decoLine1"></span
                        ></router-link>
                    </p>
                </div>
                <div class="sliderControlls" :class="theme">
                    <div class="toprew" @click="onPrew">
                        <span class="ic-icarrow-left"></span>
                    </div>
                    <div class="toright" @click="onNext">
                        <span class="ic-icarrow-right"></span>
                    </div>
                    <div class="counter">
                        <div>
                            {{ currentTab + 1 }} /
                            <span> {{ d.list.length }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="container">
            <div class="title">
                <h2>
                    {{ d.title }}
                </h2>
            </div>
            <div class="right">
                <Flickity
                    class="tabsCaptions"
                    ref="tabsSlider"
                    :options="tabsSliderOptions"
                >
                    <div
                        href="#"
                        v-for="(item, index) in d.list"
                        :key="index"
                        class="item"
                        @click="currentTab = index"
                        :class="{ active: currentTab == index }"
                    >
                        {{ item.tab }}
                        <span class="decoLine1"></span>
                    </div>
                </Flickity>
                <div class="bigLeftImg articleWrp">
                    <img
                        v-if="path(currentTabItem.image)"
                        :src="path(currentTabItem.image)"
                        alt="123"
                    />
                    <div>
                        <h2>{{ currentTabItem.title }}</h2>
                        <div v-html="currentTabItem.text"></div>
                    </div>
                    <p
                        v-if="currentTabItem.btn_name && currentTabItem.link"
                        class="moreBlk"
                    >
                        <router-link :to="currentTabItem.link" class="moreLnk"
                            >{{ currentTabItem.btn_name }}
                            <span class="decoLine1"></span
                        ></router-link>
                    </p>
                </div>
                <div class="sliderControlls" :class="theme">
                    <div class="toprew" @click="onPrew">
                        <span class="ic-icarrow-left"></span>
                    </div>
                    <div class="toright" @click="onNext">
                        <span class="ic-icarrow-right"></span>
                    </div>
                    <div class="counter">
                        <div>
                            {{ currentTab + 1 }} /
                            <span> {{ d.list.length }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Flickity from "vue-flickity";
export default {
    name: "TextTabs",
    components: {
        Flickity
    },
    props: ["theme"],
    data() {
        return {
            tabsSliderOptions: {
                prevNextButtons: false,
                cellAlign: "left",
                contain: true,
                pageDots: false
                // groupCells: true,
            },
            currentTab: 0
        };
    },
    computed: {
        currentTabItem() {
            return this.d.list[this.currentTab];
        },
        style() {
            if (this.$route.name == "kontakti") {
                return {
                    'padding-left': "0px",
                    'padding-right': "0px"
                };
            } else return null;
        }
    },
    methods: {
        onNext() {
            this.$refs.tabsSlider.next(false, false);
        },
        onPrew() {
            this.$refs.tabsSlider.previous(false, false);
        }
    },
    mounted() {
        this.$refs.tabsSlider.on("change", index => {
            this.currentTab = index;
        });
    }
};
</script>

<style lang="scss">
.textTabsSection {
    padding: 30px 0;
    .containerContact {
        @include container;
        // display: grid;
        // grid-template: auto / 1fr 3fr;
        @media (max-width: $tl) {
            display: block;
        }
        .title {
            padding-right: 30px;
            flex-shrink: 0;

            h2 {
                @include h3mob;
                margin: 0;

                @media (max-width: $ts) {
                    @include h2mob;
                }
            }
        }
        .tabsCaptions {
            margin-top: 40px;
            margin-bottom: 65px;
            @media (max-width: $tl) {
                margin-bottom: 40px;
            }
            .item {
                @include t4;
                position: relative;
                padding-bottom: 5px;
                cursor: pointer;
                margin-right: 30px;
                white-space: nowrap;
                &.active {
                    .decoLine1 {
                        &:before {
                            left: 0;
                        }
                    }
                }
                .decoLine1 {
                    background-color: transparent;
                    &:before {
                        background-color: $cred1;
                    }
                }
                &:hover {
                    .decoLine1 {
                        &:before {
                            left: 0;
                        }
                    }
                }
            }
        }
        .bigLeftImg {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 11px;
            @media (max-width: $tl) {
                display: block;
            }
            img {
                // float: left;
                width: calc(66.66% - 20px);
                margin-left: 20px;
                @media (max-width: $tl) {
                    margin-left: 0;
                }
                @media (max-width: $ts) {
                    float: unset;
                    width: 100%;
                    margin: 0 0 16px;
                }
            }
            & > div {
                margin-top: 0;
            }
            &::before,
            &::after {
                content: "";
                display: table;
            }

            &::after {
                clear: both;
            }
        }
    }
    .container {
        @include container;
        display: grid;
        grid-template: auto / 1fr 3fr;
        @media (max-width: $tl) {
            display: block;
        }
        .title {
            padding-right: 30px;
            flex-shrink: 0;

            h2 {
                @include h3mob;
                margin: 0;

                @media (max-width: $ts) {
                    @include h2mob;
                }
            }
        }
        .right {
            @media (max-width: $tl) {
                margin-top: 40px;
            }
            .tabsCaptions {
                margin-bottom: 60px;
                @media (max-width: $tl) {
                    margin-bottom: 40px;
                }
                .item {
                    @include t4;
                    position: relative;
                    padding-bottom: 5px;
                    cursor: pointer;
                    margin-right: 30px;
                    white-space: nowrap;
                    &.active {
                        .decoLine1 {
                            &:before {
                                left: 0;
                            }
                        }
                    }
                    .decoLine1 {
                        background-color: transparent;
                        &:before {
                            background-color: $cred1;
                        }
                    }
                    &:hover {
                        .decoLine1 {
                            &:before {
                                left: 0;
                            }
                        }
                    }
                }
            }
            /// TEMP
            .bigLeftImg {
                img {
                    float: left;
                    width: calc(66.66% - 20px);
                    margin-right: 20px;
                    @media (max-width: $ts) {
                        float: unset;
                        width: 100%;
                        margin: 0 0 16px;
                    }
                }
                &::before,
                &::after {
                    content: "";
                    display: table;
                }

                &::after {
                    clear: both;
                }
            }
        }
        .sliderControlls {
            margin-top: 16px;
        }
    }
    &.gray {
        background-color: $cgray6;
    }
}
</style>
