<template>
	<section class="FeedBackForm">
		<div class="formContainer">
			<div class="ft">{{ d.title }}</div>
			<div class="sub" v-if="d.subtitle">{{ d.subtitle }}</div>
			<form-input
				:placeholder="d.field_name_name"
				@change="onFieldChange"
				propname="name"
				:validate="$v.name"
				:showError="showError"
				:prevalue="name"
			></form-input>
			<form-input
				:placeholder="d.field_company_placeholder"
				@change="onFieldChange"
				propname="company"
				:validate="$v.company"
				:showError="showError"
				:prevalue="company"
			></form-input>
			<phone-input
				:placeholder="d.field_phone_name"
				@change="onPhoneChange"
				:showError="showError"
				propname="phone"
				:validate="$v.phone"
				:prevalue="phone"
			></phone-input>
			<form-input
				:placeholder="d.field_email_placeholder"
				@change="onFieldChange"
				propname="email"
				:validate="$v.email"
				:showError="showError"
				:prevalue="email"
			></form-input>

			<textarea-input
				v-if="d.field_description_visible == '1'"
				:placeholder="d.field_description_placeholder"
				@change="onFieldChange"
				propname="description"
				:validate="$v.description"
				:showError="showError"
				:prevalue="description"
			></textarea-input>

			<div class="dateCellWrp" v-if="d.field_date_visible == '1'">
				<label class="label">{{ d.field_date_name }}</label>
				<date-picker
					:placeholder="'День'"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.date"
					propname="date"
					:prevalue="date"
				></date-picker>
				<time-picker
					:placeholder="'Час'"
					:showError="showError"
					@change="onFieldChange"
					:validate="$v.time"
					propname="time"
					:prevalue="time"
				></time-picker>
			</div>

			<radio-input
				v-if="d.field_list_visible == '1'"
				:placeholder="d.field_list_name"
				:items="radioList"
				@change="onRadioChange"
				propname="peoples"
				:validate="$v.peoples"
				:showError="showError"
				:prevalue="peoples"
				:onrow="true"
			></radio-input>

			<button type="button" class="button2" @click="onSubmut">
				{{ d.btn_name }}<span class="ic-icarrow-right"></span>
			</button>
		</div>

		<modal-base v-if="successModal">
			<form-success @close="successModal = false" />
		</modal-base>
	</section>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import DatePicker from "../../Inputs/DatePickerField.vue";
import FormInput from "../../Inputs/FormInput.vue";
import PhoneInput from "../../Inputs/PhoneInput.vue";
import RadioInput from "../../Inputs/RadioInput.vue";
import TextareaInput from "../../Inputs/TextareaInput.vue";
import TimePicker from "../../Inputs/TimePicker.vue";
import { dateValidator } from "@/CustomValidators.js";
import { timeValidator } from "@/CustomValidators.js";
import ModalBase from "../../Parts/ModalBase.vue";
import FormSuccess from "../../Modals/FormSuccess.vue";
export default {
	components: {
		FormInput,
		PhoneInput,
		TimePicker,
		DatePicker,
		RadioInput,
		TextareaInput,
		ModalBase,
		FormSuccess,
	},
	name: "FeedBackForm",
	data() {
		return {
			name: "",
			company: "",
			phone: "",
			email: "",
			description: "",
			date: "",
			time: "",
			peoples: "",

			radioList: [
				"Школярі / ліцеїсти / гімназисти",
				"Учні ВНЗ (медико-фармацевтичний напрямок)",
				"Учні ВНЗ (не мед. напрямки)",
				"Інше",
			],

			showError: false,
			phoneLength: 0,
			successModal: false,
		};
	},
	methods: {
		onSubmut() {
			this.showError = true;
			if (!this.$v.$invalid) {
				this.axios
					.post("/api/request/feedback", {
						title: this.d.title,
						name: this.name,
						company: this.company,
						phone: this.phone,
						email: this.email,
						description: this.description,
						date: this.date,
						time: this.time,
						with_someone: this.peoples,
					})
					.then((response) => {
						console.log(response);
						this.successModal = true;
					});
			}
		},
	},
	validations() {
		return {
			name: {
				required,
				minLength: minLength(4),
			},
			company: {
				// required,
				minLength: minLength(4),
			},
			phone: {
				required,
				minLength: minLength(this.phoneLength),
			},
			email: {
				// required,
				email,
			},
			info: {
				// required,
			},
			date: {
				dateValidator,
			},
			time: {
				timeValidator,
			},
			peoples: {
				// required
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.FeedBackForm {
	background-color: $cgray7;
	padding: 60px 0;
	.formContainer {
		max-width: 600px;
		margin: 0 auto;
		.ft {
			@include h3;
		}
		.sub {
			@include t3l;
			margin-top: 20px;
		}
		.button2 {
			width: 100%;
			max-width: unset;
			background-color: $cred2;
			color: $cwhite;
			cursor: pointer;
		}
		// .dateCellWrp {
		// 	display: grid;
		// 	grid-template-columns: 150px 1fr 1fr;
		// 	grid-gap: 10px;
		// 	padding: 20px 0;
		// 	.label {
		// 		font-style: normal;
		// 		font-weight: 500;
		// 		font-size: 14px;
		// 		line-height: 130%;
		// 		letter-spacing: 0.02em;
		// 		color: #585b6d;
		// 	}
		// }
	}
}
</style>